import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import firebase from '../../../services/firebase';
import { BiArrowBack } from 'react-icons/bi';
import loader from '../../../assets/imgs/loader.gif';

class EditRigNext extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assets: [],
			data: [],
			load: false,
			validateTrigger: false,
			id: props.match.params.id,
			rig: {},
			Loader: false,
			currentUser: props.auth.currentUser,
		};
	}

	componentDidMount() {
		this.getSelectedRig();
	}

	getSelectedRig() {
		this.setState({ Loader: true });
		const { rigsList } = this.props.rig;
		const temp = rigsList.find((item) => item.$key === this.state.id);
		this.setState({
			rig: {...temp, releaseDate: temp.releaseDate.replace('Z', '')},
			Loader: false
		});
	}

	onInputChange(key, value) {
		const { rig } = this.state;
		rig[key] = value;
		this.setState({ ...rig });

		if (rig.estDays && rig.actualDaysOfRigmove && rig.estDays !== '' && rig.actualDaysOfRigmove !== '') {
			rig.estimatedVsActual = rig.actualDaysOfRigmove - rig.estDays;
		} else {
			rig.estimatedVsActual = 0;
		}


		if (rig.actualDaysOfRigmove && rig.actualDaysOfRigmove !== '') {
			if ((rig.NptHours) && (rig.NptHours !== 0)) {
				rig.WoNptDays = rig.actualDaysOfRigmove - rig.NptDays;
				rig.WoNptHours = 24 - rig.NptHours;

				if (((rig.NptDays === 0) || (!rig.NptDays)) && (rig.NptHours !== 0)) {
					rig.NptDays = 0;
					rig.WoNptDays = rig.actualDaysOfRigmove;
					rig.WoNptHours = 24 - rig.NptHours;
				}
				if (rig.WoNptDays >= 1) {
					rig.WoNptDays -= 1;
				}
				if ((rig.WoNptDays > 0) && (rig.WoNptDays < 1)) {
					rig.WoNptHours = 0;
					rig.WoNptDays += 1;
				}
			} else {
				rig.WoNptDays = rig.actualDaysOfRigmove - rig.NptDays;
			}
		}
		if ((rig.NptHours === 0)) {
			rig.NptHours = 0;
			rig.WoNptHours = 0;
		}
		if ((rig.NptDays === 0) && (rig.NptHours === 0)) {
			rig.NptDays = 0;
			rig.WoNptDays = 0;
			rig.NptHours = 0;
			rig.WoNptHours = 0;
		}
	}

	handleSelectChange = (type, value) => {
		const { rig } = this.state;
		rig[type] = value;
		this.setState({ rig });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form } = this.props;
		const { rig } = this.state;
		
		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setState({
					errorMessage: ''
				});

				if (rig.NptHours >= 24) {
					return;
				} else {
					rig.updatedAt = moment().valueOf();
					rig.updatedBy = localStorage.getItem('user');

					const db = firebase.firestore();
					await db.doc(`rigs/${this.state.id}`).update(rig)
						.then(() => this.props.history.push(`/rigs/optional/${rig.$key}`))
						.catch(() => {});
				}

			}
		});
	}

	render() {
		const { validateTrigger, rig, Loader } = this.state;
		const { form: { getFieldDecorator } } = this.props;
		const { Option } = Select;

		if (Loader === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="edit-rig-area edit-rig-asset-part">
				<h4 className="card-title no-mb icon">
					<BiArrowBack
						size={40}
						onClick={() => this.props.history.push(`/rigs/edit/${rig.$key}`)}
					/>
					Edit Rig #{rig.number}
				</h4>
				<div className="card-body">
					<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form">
						<Row>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="AFE #">
									{getFieldDecorator('afeNumber', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.afeNumber,
									})(
										<Input onChange={(e) => this.onInputChange('afeNumber', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Type">
									{getFieldDecorator('type', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.type
									})(
										<Select
											size="large"
											dropdownClassName="custom-select-option" 
											className="custom-select-multiple"
											onChange={(value) => this.handleSelectChange('type', value)}
										>
											<Option value="Apex">Apex</Option>
											<Option value="BOSS">BOSS</Option>
											<Option value="Box on Box">Box on Box</Option>
											<Option value="Conventional">Conventional</Option>
											<Option value="Down-Hole">Down-Hole</Option>
											<Option value="F-Rigs">F-Rigs</Option>
											<Option value="Flex 3">Flex 3</Option>
											<Option value="Flex 3S">Flex 3S</Option>
											<Option value="Flex 3W">Flex 3W</Option>
											<Option value="Flex 4">Flex 4</Option>
											<Option value="Flex 5">Flex 5</Option>
											<Option value="M-Rigs">M-Rigs</Option>
											<Option value="NOV">NOV</Option>
											<Option value="Other">Other</Option>
											<Option value="Percussion">Percussion</Option>
											<Option value="RIG STYLE">RIG STYLE</Option>
											<Option value="ROCKET">ROCKET</Option>
											<Option value="Rotary">Rotary</Option>
											<Option value="S-Rigs">S-Rigs</Option>
											<Option value="SLING SHOT">SLING SHOT</Option>
											<Option value="Super Single">Super Single</Option>
											<Option value="Tele-Double">Tele-Double</Option>
											<Option value="Triple Box">Triple Box</Option>
											<Option value="WOLFSLAYER">WOLFSLAYER</Option>
											<Option value="Workover">Workover</Option>
											<Option value="X-Rigs">X-Rigs</Option>
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Weather conditions">
									{getFieldDecorator('weather', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.weather,
									})(
										<Select
											getPopupContainer={() => document.getElementById('DropdownFixPostion')}
											size="large"
											mode="multiple"
											dropdownClassName="custom-select-option" 
											className="custom-select-multiple"
											onChange={(value) => this.handleSelectChange('weather', value)}
										>
											<Option value="Hot/Dry">Hot/Dry</Option>
											<Option value="Sunny/Clear">Sunny/Clear</Option>
											<Option value="Partially Cloudy">Partially Cloudy</Option>
											<Option value="Cloudy">Cloudy</Option>
											<Option value="Overcast">Overcast</Option>
											<Option value="Windy">Windy</Option>
											<Option value="Rain">Rain</Option>
											<Option value="Drizzle">Drizzle</Option>
											<Option value="Foggy">Foggy</Option>
											<Option value="Snow">Snow</Option>
											<Option value="Stormy">Stormy</Option>
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Est. Miles to move">
									{getFieldDecorator('estMiles', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.estMiles,

									})(
										<Input type="number" onChange={(e) => this.onInputChange('estMiles', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Est. days to move">
									{getFieldDecorator('estDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.estDays
									})(
										<Input type="number" onChange={(e) => this.onInputChange('estDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Actual move days">
									{getFieldDecorator('actualDaysOfRigmove', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.actualDaysOfRigmove,
									})(
										<Input type="number" onChange={(e) => this.onInputChange('actualDaysOfRigmove', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Estimated vs Actual">
									{getFieldDecorator('estimatedVsActual', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.estimatedVsActual
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('estimatedVsActual', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="primaryColor no-mb"><b>NPT Time</b></p>
								<Form.Item label="Hours">
									{getFieldDecorator('NptHours', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.NptHours,
									})(
										<Input type="number" onChange={(e) => this.onInputChange('NptHours', e.target.value)} />
									)}
								</Form.Item>
								{rig.NptHours >= 24 &&
									<label>Hours should be less than 24</label>
								}
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="no-mb">&nbsp;</p>
								<Form.Item label="Days">
									{getFieldDecorator('NptDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.NptDays
									})(
										<Input type="number" onChange={(e) => this.onInputChange('NptDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="primaryColor no-mb"><b>Move Time w/o NPT</b></p>
								<Form.Item label="Hours">
									{getFieldDecorator('WoNptHours', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.WoNptHours,
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('WoNptHours', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="no-mb">&nbsp;</p>
								<Form.Item label="Days">
									{getFieldDecorator('WoNptDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.WoNptDays
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('WoNptDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
						</Row>
						<Form.Item className="button-section" style={{ paddingLeft: 10 }}>
							<Button htmlType="submit" size="large" className="submitBtn">
								Save & Continue
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(EditRigNext)));