import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Input, Modal } from 'antd';
import { BsExclamationTriangle } from 'react-icons/bs';
import './safety-form.css';
import AssetScreen from './asset-screen';
import FormContent from './form-content';
import moment from 'moment';
import firebase from '../../../services/firebase';
import loader from '../../../assets/imgs/loader.gif';
import { isEmpty } from '../../../utils/activity-logs';
import { checkDeletePermissions, checkWritePermissions } from '../../../utils/native';

class AddJSA extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: props.match.params.id === 'add' ? true : false,
			isWritePermitted: checkWritePermissions(props, 'losSafety'),
			isDeletePermitted: checkDeletePermissions(props, 'losSafety'),
			validateTrigger: false,
			title: '',
			documentList: [],
			documentListRef: [],
			objectModal: false,
			selectedObject: '',
			selectedTemplate: '',
			formModal: false,
			fieldSelected: '',
			displayDragItems: false,
			formTitle: '',
			confirmModal: false,
			admins: [],
			isLoading: false,
			assetId: props.match.params.id !== 'add' ? props.match.params.id : null,
			assetDetails: {},
			objectList: [],
			formId: '',
			formAction: 'add',
			formValues: null
		};
	}

	componentDidUpdate(prevProps) {
		const { isWritePermitted } = this.state;
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			const checkWrite = checkWritePermissions(this.props, 'losSafety');
			if (checkWrite === isWritePermitted) {
				this.setState({
					isDeletePermitted: checkDeletePermissions(this.props, 'losSafety')
				});
			} else {
				this.setState({
					isWritePermitted: checkWritePermissions(this.props, 'losSafety'),
					isDeletePermitted: checkDeletePermissions(this.props, 'losSafety'),
					selectedObject: ''
				});
			}
		}
	}

	componentDidMount() {
		if (this.props.match.params.id !== 'add') {
			this.getAssetDetails();
			this.getObjectDetails();
		}
	}

	getAssetDetails = () => {
		const { assetId } = this.state;
		this.setState({ isLoading: true });
		const db = firebase.firestore();
		const asset = db.collection('safetyForm').where('id', '==', assetId);
		asset.get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					let data = doc.data();
					if (data.archive !== true) {
						this.setState({ assetDetails: data, title: data.title, isLoading: false });
					}
				} else {
					this.setState({ isLoading: false });
				}
			});
		});
	}

	getObjectDetails = () => {
		const { assetId } = this.state;
		this.setState({ isLoading: true, objectList: [] });
		const db = firebase.firestore();
		const users = db.collection('safetyForm').doc(assetId).collection('form');
		users.get().then((querySnapshot) => {
			let temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					let data = doc.data();
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			temp = temp.filter((obj, index) => isEmpty(obj.id) ? obj.id = (index + 1).toString() : obj);
			temp.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);
			this.setState({ objectList: temp });
		});
	}

	getFormContentDetails = (object) => {
		const { assetId } = this.state;
		this.setState({ isLoading: true });
		const db = firebase.firestore();
		const users = db.collection('safetyForm').doc(assetId).collection('form').doc(object.documentId).collection('formContent');
		users.get().then((querySnapshot) => {
			let temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					let data = doc.data();
					temp.push(data);
				}
			});
			// console.log("documentList: ", temp);

			temp.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);
			this.setState({
				selectedObject: object.selectedObject,
				selectedTemplate: object.selectedTemplate,
				formTitle: object.formTitle,
				formId: object.documentId,
				// formId: object.id,
				documentList: Object.assign([], temp),
				documentListRef: Object.assign([], temp),
				formAction: 'edit',
				isLoading: false
			});
		});
	}

	updateFormOrderList = (list) => {
		const { assetId } = this.state;
		this.setState({ objectList: list });

		const db = firebase.firestore();
		let listRef = db.collection('safetyForm').doc(assetId);
		list.forEach((form) => {
			listRef.collection('form').doc(form.documentId).update(form);
		});
	}

	deleteObjectDetails = async (object) => {
		const { objectList } = this.state;
		let index = objectList.indexOf(object);
		objectList[index].archive = true;

		await this.deleteFormObject(object);
		objectList.splice(index, 1);
		const result = objectList.filter((obj, index) => obj.id = (index + 1).toString());
		this.updateFormOrderList(result);
		this.setState({ objectList: result });
	}

	deleteFormObject = (object) => {
		const { assetId } = this.state;

		const db = firebase.firestore();
		// db.collection('safetyForm').doc(assetId).collection('form').doc(object.documentId).update(object);
		db.collection('safetyForm').doc(assetId).collection('form').doc(object.documentId).delete();
	}

	handleOk = (e) => {
		e.preventDefault();
		const { form } = this.props;
		form.validateFields(async (err, values) => {
			this.handleValidator(true);
			if (!err) {
				this.setState({ showModal: false, title: values.new_asset, confirmModal: true });
				this.setState({ loader: true });
			}
		});
	};

	onConfirm = () => {
		const { title } = this.state;
		this.setState({ confirmModal: false });
		this.getAllAdmins().then(async (adminUsers) => {
			const data = {
				title: title,
				createdBy: localStorage.getItem('user'),
				createdAt: moment().valueOf(),
				assignedTo: [],
				administeredBy: adminUsers
			};
			const db = firebase.firestore();
			let documentId = await db.collection('safetyForm').doc().id;
			data.id = documentId;
			db.collection('safetyForm').doc(documentId).set(data).then(() => {
				this.setState({ assetId: documentId });
				this.props.history.replace(`/jsaList/${data.id}`);
			});
		});
	}

	getAllAdmins() {
		return new Promise((resolve) => {
			const { usersList } = this.props.auth;
			const admins = usersList.filter((item) => item.userType === 'admin' && item.archive !== true);
			this.setState({ admins });
			resolve(admins);
		});
	}

	handleValidator = (value) => {
		this.setState({ validateTrigger: value });
	}

	handleCancel = () => {
		this.setState({ showModal: false });
		this.props.history.push('/jsaList');
	};

	handleClose = () => {
		const { form } = this.props;
		this.setState({ formModal: false, displayDragItems: false });
		form.resetFields();
	}

	getSelectedObject = (key, value) => {
		this.setState({ [key]: value });
	}

	updateModalState = (key, value) => {
		this.setState({ [key]: value });
		// Resets form title & fields when new form added
		this.setState({ formTitle: '', documentList: [] });
	}

	addFormField = (value) => {
		this.setState({ documentList: value, documentListRef: value });
	}

	updateFormList = (value) => {
		this.setState({ documentList: value });
	}

	handleFormFields = (value) => {
		this.setState({ formModal: true, fieldSelected: value, displayDragItems: true, formValues: null });
	}

	handleEditFormFields = (value) => {
		if (value.type === 'description') {
			this.setState({ displayDragItems: true, formValues: value });
		} else {
			this.setState({ formModal: true, fieldSelected: value.type, displayDragItems: true, formValues: value });
		}
	}

	updateFormField = (value) => {
		this.setState({ formValues: value });
	}

	getFormTitle = (value) => {
		this.setState({ formTitle: value });
	}

	saveFormContent = async () => {
		const { formTitle, selectedObject, selectedTemplate, assetId, formId, formAction, objectList } = this.state;
		this.setState({ isLoading: true });

		const formData = {
			formTitle: formTitle,
			selectedObject: selectedObject,
			selectedTemplate: selectedTemplate,
			createdAt: moment().valueOf(),
			createdBy: localStorage.getItem('user')
		};
		const db = firebase.firestore();
		if (formAction === 'add') {
			const safetyRef = db.collection('safetyForm').doc(assetId);
			//add query
			let documentId = await safetyRef.collection('form').doc().id;
			formData.documentId = documentId;
			formData.id = (objectList.length + 1).toString();
			safetyRef.collection('form').doc(documentId).set(formData).then(() => {
				const formRef = safetyRef.collection('form').doc(documentId);
				this.addFormContent(formRef);
			});
		} else {
			const formRef = db.collection('safetyForm').doc(assetId).collection('form').doc(formId);
			await this.deleteFormContent(formRef);
			this.updateFormContent(formRef);
		}
	}

	addFormContent = (formRef) => {
		const { documentList } = this.state;
		documentList.forEach((doc, index) => {
			const formId = formRef.collection('formContent').doc().id;
			doc.documentId = formId;
			formRef.collection('formContent').doc(formId).set(doc);
			if (index === (documentList.length - 1)) {
				this.getSelectedObject('selectedObject', '');
				this.getObjectDetails();
				this.setState({ isLoading: false });
			}
		});
	}

	deleteFormContent = (formRef) => {
		const { documentList, documentListRef } = this.state;
		return new Promise((resolve) => {
			const deletedDocsList = documentListRef.filter((document) =>
				!documentList.some((doc) => document.id === doc.id && document.label === doc.label)
			);

			deletedDocsList.forEach((doc) => {
				if (!isEmpty(doc.documentId)) {
					formRef.collection('formContent').doc(doc.documentId).delete();
				}
			});
			resolve(true);
		}).catch(() => this.setState({ isLoading: false }));
	}

	updateFormContent = (formRef) => {
		const { documentList } = this.state;
		documentList.forEach((doc, index) => {
			if (isEmpty(doc.documentId)) {
				const documentId = formRef.collection('formContent').doc().id;
				doc.documentId = documentId;
				formRef.collection('formContent').doc(documentId).set(doc);
			} else {
				formRef.collection('formContent').doc(doc.documentId).update(doc);
			}
			if (index === (documentList.length - 1)) {
				this.getSelectedObject('selectedObject', '');
				this.setState({ isLoading: false });
			}
		});
	}

	render() {
		const { validateTrigger, showModal, title, formModal, fieldSelected, objectModal, selectedObject, selectedTemplate, documentList, displayDragItems,
			formTitle, confirmModal, isLoading, objectList, formValues, isWritePermitted, isDeletePermitted } = this.state;
		const { form, form: { getFieldDecorator } } = this.props;

		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<>
				{/* Header section */}
				<div className="warning-headline no-mb">
					<BsExclamationTriangle style={{ fontSize: '30px' }} />
					{selectedObject === '' ?
						title === '' ?
							<h4 className="no-mb">Untitled Asset</h4>
							:
							<h4 className="no-mb">{title}</h4>
						:
						formTitle === '' ?
							<h4 className="no-mb">Form Name</h4>
							:
							<h4 className="no-mb">{formTitle}</h4>
					}
				</div>

				{selectedObject === '' &&
					<AssetScreen
						title={title}
						objectModal={objectModal}
						selectedTemplate={selectedTemplate}
						updateModalState={(key, value) => this.updateModalState(key, value)}
						getSelectedObject={(key, value) => this.getSelectedObject(key, value)}
						objectList={objectList}
						getFormContentDetails={(object) => this.getFormContentDetails(object)}
						updateFormOrderList={(list) => this.updateFormOrderList(list)}
						deleteObjectDetails={(list) => this.deleteObjectDetails(list)}
						isWritePermitted={isWritePermitted}
						isDeletePermitted={isDeletePermitted}
					/>
				}

				{/* Form details */}
				{selectedObject === 'form' &&
					<FormContent
						form={form}
						formModal={formModal}
						formTitle={formTitle}
						formValues={formValues}
						validateTrigger={validateTrigger}
						handleValidator={(value) => this.handleValidator(value)}
						fieldSelected={fieldSelected}
						documentList={documentList}
						handleFormFields={(value) => this.handleFormFields(value)}
						handleEditFormFields={(value) => this.handleEditFormFields(value)}
						handleClose={() => this.handleClose()}
						updateFormList={(value) => this.updateFormList(value)}
						addFormField={(value) => this.addFormField(value)}
						displayDragItems={displayDragItems}
						getFormTitle={(value) => this.getFormTitle(value)}
						getSelectedObject={(key, value) => this.getSelectedObject(key, value)}
						saveFormContent={this.saveFormContent}
						isDeletePermitted={isDeletePermitted}
					/>
				}


				<Modal className="send-mail-modal" title="New Asset" visible={showModal} onOk={this.handleOk} onCancel={this.handleCancel} maskClosable={false}>
					<Form layout="vertical" className="theme-form">
						<Form.Item label="Enter Course Name">
							{title === '' ?
								getFieldDecorator('new_asset', {
									validateTrigger: validateTrigger ? 'onChange' : '',
									rules: [
										{ required: true, message: 'Please enter asset title' },
									],
									initialValue: ''
								})(
									<Input />
								)
								:
								getFieldDecorator('new_asset', {
									validateTrigger: validateTrigger ? 'onChange' : '',
									initialValue: ''
								})(
									<Input />
								)
							}
						</Form.Item>
					</Form>
				</Modal>


				<Modal
					className="send-mail-modal"
					title='Add New Asset'
					visible={confirmModal}
					onOk={this.onConfirm}
					onCancel={() => this.handleCancel()}
					maskClosable={false}>
					<p>Are you sure, you want to add this asset?</p>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(AddJSA)));