export const LOG_IN_START = 'LOG_IN_START';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const USER_SUBSCRIBE_START = 'USER_SUBSCRIBE_START';
export const USER_SUBSCRIBE_SUCCESS = 'USER_SUBSCRIBE_SUCCESS';
export const USERS_LIST = 'USERS_LIST';


/* LOGIN ACTIONS */
export const logInStart = () => ({
	type: LOG_IN_START
});

export const logInSuccess = (data) => ({
	type: LOG_IN_SUCCESS,
	data
});

export const logout = () => ({
	type: LOG_OUT
});

export function handleLogin(data) {
	return (dispatch) => {
		dispatch(logInStart());
		setTimeout(() => {
			dispatch(logInSuccess(data));
		}, 2000);
	};
}

export const userSubscribeStart = () => ({
	type: USER_SUBSCRIBE_START
});

export const userSubscribeSuccess = () => ({
	type: USER_SUBSCRIBE_SUCCESS
});

export const usersList = (data) => ({
	type: USERS_LIST,
	data
});