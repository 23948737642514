import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../services/firebase';
import loader from '../../../assets/imgs/loader.gif';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Upload, Select, Alert, Modal, DatePicker, TimePicker, Radio } from 'antd';
import { AiTwotoneDelete } from 'react-icons/ai';
import { saveAs } from 'file-saver';
import { sendRigPic } from '../../../services/auth';
import { addActivityLogs, isEmpty } from '../../../utils/activity-logs';
import moment from 'moment';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { addQuery, updateQuery } from '../../../services/firebaseProvider';
import { checkDeletePermissions, checkWritePermissions } from '../../../utils/native';

const Option = Select.Option;

class EditPusher extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'pusher'),
			isDeletePermitted: checkDeletePermissions(props, 'pusher'),
			id: this.props.match.params.id,
			pusher: [],
			rig: '',
			load: false,
			part: '',
			task: '',
			validateTrigger: false,
			errorMessage: false,
			submitted: false,
			load_part: false,
			load_task: false,
			isModalVisible: false,
			emailDetails: {},
			formSubmitted: false,
			isDelete: false,
			deleteIndex: '',
			cannedList: [],
			cannedListUp: [],
			desc: '',
			time: null,
			addDescription: false,
			refreshCannedMsg: false,
			addImage: false,
			imageAction: '',
			users: props.auth.usersList,
			images: [],
			imageDetails: {}
		};
	}

	onLoad = () => {
		this.setState({
			load: true
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'pusher'), isDeletePermitted: checkDeletePermissions(this.props, 'pusher') });
		}
		const { usersList } = this.props.auth;
		if (prevProps.auth.usersList.length !== usersList.length) {
			this.setState({ users: usersList });
		}
	}

	componentDidMount() {
		this.getPusherNote();
	}

	async getPusherNote() {
		const db = firebase.firestore();
		const reportsRef = db.collection('pusher').where('$key', '==', this.state.id);
		reportsRef.get().then((querySnapshot) => {
			const temp = [];
			let imagesList = [];
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (!isEmpty(data.images) && data.images.length > 0) {
						imagesList = data.images.map(img => ({ url: img }));
					}
					temp.push(data);
				}
			});
			this.setState({ pusher: temp });
			if (imagesList.length > 0) {
				this.setState({ images: imagesList });
			}
			if (temp.length > 0) {
				this.getPusherPics();
				this.getRig(temp[0].rigId);
				this.getRigParts();
				this.getRigTasks();
				this.getCannedList();
				this.getCannedListUp();
			}
		});
	}

	getPusherPics() {
		const { users, id } = this.state;

		const db = firebase.firestore();
		const pusherRef = db.collection('pusher').doc(id).collection('images').orderBy('id', 'asc');
		pusherRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					// if (!isEmpty(data.images) && data.images.length > 0 && !data.pusherPics) {
					if (!isEmpty(data.url) && !isEmpty(data.createdBy)) {
						const userIndex = users.map(user => user.uid).indexOf(data.createdBy);
						if (userIndex !== -1) {
							data.user = users[userIndex].firstName + ' ' + users[userIndex].lastName;
						}
					}
					temp.push(data);
				}
			});
			// if (this.state.images.length > 0) {
			this.setState({ images: temp });
			// }
		});
	}

	getRig(rigId) {
		const { rigsList } = this.props.rig;
		const temp = rigsList.find((item) => item.$key === rigId);
		this.setState({
			rig: {...temp, releaseDate: temp.releaseDate.replace('Z', '')},
			Loader: false
		});
	}

	getRigParts() {
		const {partsList} = this.props.rig;
		this.setState({ part: partsList, load_part: true });
	}

	getRigTasks() {
		const {tasksList} = this.props.rig;
		this.setState({ task: tasksList, load_task: true });
	}

	getCannedList() {
		const databse = firebase.firestore();
		const partsRef = databse.collection('cannedList').where('type', '==', 'Rig Down');
		partsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const response = doc.data();
					response.$key = doc.id;
					temp.push(response);
				}
			});

			this.setState({ cannedList: temp });
		});
	}

	getCannedListUp() {
		const databse = firebase.firestore();
		const partsRef = databse.collection('cannedList').where('type', '==', 'Rig Up');
		partsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const response = doc.data();
					response.$key = doc.id;
					temp.push(response);
				}
			});

			this.setState({ cannedListUp: temp });
		});
	}

	handleClick(key, value) {
		const { pusher } = this.state;
		pusher[0][key] = value;
		this.setState({ ...pusher });
	}

	onInputChange(key, value) {
		const { pusher } = this.state;
		const oldArray = pusher;
		oldArray[0][key] = value;
		if (key === 'type') {
			oldArray[0]['cannedMsg'] = [];
		}
		this.setState({ pusher: oldArray, refreshCannedMsg: true });
		setTimeout(() => { this.setState({ refreshCannedMsg: false }); }, 10);
	}

	onUpdateInput(key, index, value) {
		const { pusher } = this.state;
		const oldArray = pusher[0].entries;
		if (key === 'time') {
			oldArray[index][key] = moment(value).format('YYYY-MM-DDTHH:mm');
		} else {
			oldArray[index][key] = value;
		}
		pusher[0].entries = oldArray;
		this.setState({ pusher });
	}

	handleDescription() {
		const { pusher, desc, time } = this.state;
		let oldArray = pusher[0];
		if (!pusher[0].entries) {
			oldArray = pusher[0];
			oldArray.entries = [{ description: pusher[0].description, time: pusher[0].time }];
		}
		oldArray.entries.push({ description: desc, time: time });
		pusher[0].entries = oldArray.entries;
		this.setState({ desc: '', time: null, addDescription: false });
	}

	onSelectChange(key, value) {
		const { pusher } = this.state;
		const temp = [];
		for (let i = 0; i < value.length; i++) {
			temp.push(value[i]);
		}
		pusher[0][key] = temp;
		this.setState({ pusher });
	}

	onChange = async (index, info) => {
		const { images } = this.state;
		if (!isEmpty(info) && info.file && info.file.originFileObj) {
			const type = info.file.originFileObj.type;
			const src = await new Promise(resolve => {
				if (type.split('/')[0] === 'image') {
					const reader = new FileReader();
					reader.readAsDataURL(info.file.originFileObj);
					reader.onload = () => resolve(reader.result);
					reader.onerror = () => { };
				} else {
					this.setState({ errorMessage: true }, () => setTimeout(() => this.setState({ errorMessage: false }), 5000));
				}
			});

			if (type.split('/')[0] === 'image') {
				//Add image url		
				const image = new Image();
				image.src = src;
				if (index === null) {
					images.push({ url: src, id: moment().valueOf(), createdBy: localStorage.getItem('user') });
					this.setState({ imageDetails: { url: src, id: moment().valueOf(), createdBy: localStorage.getItem('user') } });
				} else {
					images.map((pic) => {
						if (pic.id === index) {
							this.setState({ imageDetails: { $key: pic.$key, url: src, createdBy: localStorage.getItem('user'), updatedAt: moment().valueOf() } });
						}
						return pic;
					});
				}
				this.toggleAddImage('update');
			}
		}
	};

	customRequest = (value) => {
		if (value.file.type.split('/')[0] === 'image') {
			const reader = new FileReader();
			reader.onload = () => {
				this.setState({ imageDetails: { url: reader.result, id: moment().valueOf(), createdBy: localStorage.getItem('user') } });
				this.toggleAddImage('add');
			};
			var blob = new Blob([value.file], { type: value.file.type });
			reader.readAsDataURL(blob);
		} else {
			this.setState({ errorMessage: true }, () => setTimeout(() => this.setState({ errorMessage: false }), 5000));
		}
	}

	toggleAddImage(action) {
		const { addImage } = this.state;
		this.setState({ addImage: !addImage, imageAction: action });
	}

	confirmAddImage = async() => {
		const { id, imageDetails, images, imageAction } = this.state;
		if (imageAction === 'add') {
			const response = await addQuery('pusher', id, 'images', imageDetails).catch((err) => new Error(err));
			this.toggleAddImage();
			const oldArray = images;
			response.details.user = localStorage.getItem('fullName');
			oldArray.push(response.details);
			this.setState({ images: oldArray });
		} else {
			await updateQuery('pusher', id, 'images', imageDetails).catch((err) => new Error(err));
			this.toggleAddImage();
			images.map((pic) => {
				if (pic.$key === imageDetails.$key) {
					pic.url = imageDetails.url;
					pic.createdBy = imageDetails.createdBy;
					pic.updatedAt = imageDetails.updatedAt;
					pic.user = localStorage.getItem('fullName');
				}
				return pic;
			});
			this.setState({ images });
		}
	}

	onDateChange = (key, value) => {
		const { pusher } = this.state;
		if (key === 'date') {
			pusher[0][key] = moment(value).format('YYYY-MM-DD');
		}
		if (key === 'time') {
			pusher[0][key] = moment(value).format('YYYY-MM-DDTHH:mm');
		}
		this.setState({ ...pusher });
	}

	onDeletePic(index) {
		const { isDelete } = this.state;
		this.setState({ isDelete: !isDelete, deleteIndex: index });
	}

	async confirmDelete() {
		const { pusher, images, deleteIndex } = this.state;

		images.map(async(pics) => {
			if (pics.id === deleteIndex) {
				pics.archive = true;
				await updateQuery('pusher', pusher[0].$key, 'images', pics).catch((err) => new Error(err));
			}
			return pics;
		});

		let count = 0;
		images.map((pics) => {
			if (pics.archive === true) {
				count += 1;
			}
			return pics;
		});
		if (count === images.length) {
			pusher[0].archive = true;
		}

		if (count === images.length) {
			const db = firebase.firestore();
			await db.doc(`pusher/${pusher[0].$key}`).update(pusher[0])
				.then(() => count === images.length && this.updateLogs('delete'))
				.catch(() => { });
		}

		this.onDeletePic(null);
		this.setState({ pusher });
	}

	onValueChanges(key, value) {
		const { emailDetails } = this.state;
		emailDetails[key] = value;
		this.setState({ ...emailDetails });
	}

	onArchive() {
		const { pusher } = this.state;
		saveAs(pusher[0].url);
	}

	onSend() {
		const { pusher } = this.state;
		const temp = pusher[0].url.split(';')[0];
		const type = temp.split('/')[1];
		const data = {
			file: pusher[0].url,
			type: type
		};
		this.setState({ emailDetails: data });
		this.showModal();
	}

	showModal() {
		this.setState({ isModalVisible: true });
	}

	handleOk(e) {
		e.preventDefault();
		const { form } = this.props;
		const { emailDetails } = this.state;
		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setState({ formSubmitted: true });
				sendRigPic(emailDetails)
					.then((res) => {
						alert(res.data.success);
						this.setState({ formSubmitted: false, isModalVisible: false });
						this.setState({ isModalVisible: false });
					}).catch((e) => {
						alert(e);
						this.setState({ formSubmitted: false, isModalVisible: false });
					});
			}
		});
	}

	handleCancel() {
		this.setState({ isModalVisible: false });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form } = this.props;
		const { pusher } = this.state;
		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setState({
					errorMessage: ''
				});
			}
			this.setState({ submitted: false });

			delete pusher[0].description;
			const db = firebase.firestore();
			await db.doc(`pusher/${pusher[0].$key}`).update(pusher[0])
				.then(() => this.updateLogs('update'))
				.catch(() => { });
		});
	}

	async updateLogs(action) {
		const { rig, pusher } = this.state;
		await addActivityLogs(rig, action, 'Pusher Note', pusher[0].$key).then(() => {
			this.props.history.push('/pics');
		});
	}

	render() {
		const { validateTrigger, part, task, errorMessage, submitted, load_part, load_task, isModalVisible, emailDetails, formSubmitted, isDelete,
			pusher, cannedList, cannedListUp, desc, time, addDescription, refreshCannedMsg, addImage, images, imageAction,
			isDeletePermitted } = this.state;
		const { form: { getFieldDecorator } } = this.props;

		if (this.state.pusher.length === 0 || formSubmitted) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="edit-rig-area edit-rig-pic-part">
				{/* <header className='reportHeader site_header'>
					<h1 className='header'>Rig Pic</h1>
				</header> */}
				<h4 className="card-title no-mb">Edit Pusher Pic</h4>

				<Upload
					// className="rig-photo-upload-area"
					listType="picture"
					showUploadList={false}
					customRequest={(value) => this.customRequest(value)}
				>
					<IoIosAddCircleOutline size={30} />
				</Upload>
				<div className="edit-rig-inner">
					{pusher.map((picture, index) => {
						return (
							<div key={index}>
								<div className="rig-photo-form-img-area">
									<div className="left-area">
										<div className="card-body">
											{images && images.map((image, index) => {
												return (
													<div key={index.toString()}>
														{image.archive !== true &&
															<div className="rig-photos-part">
																<>
																	<Upload
																		className="rig-photo-upload-area"
																		listType="picture"
																		showUploadList={false}
																		onChange={(value) => this.onChange(image.id, value)}
																		customRequest={(value) => this.onChange(image.id, value)}
																	>
																		{/* {'Upload'} */}
																		{image.url ?
																			<img src={image.url} className="edit-image" alt="asset" />
																			:
																			<div className="upload-btn">Upload</div>
																		}
																	</Upload>
																	{(image.url && isDeletePermitted) &&
																		<>
																			<AiTwotoneDelete size={20} className="deleteIcon" onClick={() => this.onDeletePic(image.id)} />
																		</>
																	}
																</>

																{(!isEmpty(image.user) && image.createdBy) &&
																	<p className='user-details'>Captured By: {image.user}</p>
																}

																{errorMessage ? <Alert message="Please upload image only" type="error" style={{ margin: '15px auto', width: '50%' }} /> :
																	submitted && <Alert message="Please upload image" type="error" style={{ margin: '15px auto', width: '50%' }} />}
															</div>
														}
													</div>
												);
											})}
										</div>
									</div>
									<div className="right-area">
										<div className="card-body">
											<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form">
												<Row>
													<Col md={24} xl={12} span={24} id="DropdownFixPostion">
														<Form.Item label="Date">
															{getFieldDecorator('date', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.date && picture.date !== '' ? moment(picture.date) : null,
															})(
																<DatePicker
																	// getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
																	// showTime
																	format="DD/MMM/YY"
																	onChange={(e) => this.onDateChange('date', e)}
																	style={{ width: '100%' }}
																/>
															)}
														</Form.Item>
													</Col>
													<Col md={24} xl={12} span={24} id="DropdownFixPostion">
														<Form.Item label="Time">
															{getFieldDecorator('time', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.time && picture.time !== '' ? moment(picture.time) : null,
															})(
																<TimePicker
																	// getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
																	showTime
																	format="HH:mm"
																	onChange={(e) => this.onDateChange('time', e)}
																	style={{ width: '100%' }}
																/>
															)}
														</Form.Item>
													</Col>
													{/* Rig Part */}
													<Col md={24} xl={12} id="DropdownFixPostion">
														{load_part ?
															<Form.Item label="Rig part">
																{getFieldDecorator('part', {
																	validateTrigger: validateTrigger ? 'onChange' : '',
																	initialValue: picture.part
																})(
																	<Select
																		dropdownClassName="custom-select-option"
																		className="custom-select-multiple"
																		getPopupContainer={() => document.getElementById('DropdownFixPostion')}
																		mode="multiple"
																		labelInValue={false}
																		tokenSeparators={[' ', ',']}
																		onChange={(value) => this.onSelectChange('part', value)}
																	>
																		{part && part.length > 0 && part.map((key) => {
																			return (
																				<Option key={key.name} value={key.name}>{key.name}</Option>
																			);
																		})}
																	</Select>
																)}
															</Form.Item>
															:
															<Form.Item label="Rig part">
																{getFieldDecorator('part', {
																	validateTrigger: validateTrigger ? 'onChange' : ''
																})(
																	<Input disabled={true} />
																)}
															</Form.Item>
														}
													</Col>
													{/* Task */}
													<Col md={24} xl={12} id="DropdownFixPostion">
														{load_task ?
															<Form.Item label="Task">
																{getFieldDecorator('task', {
																	validateTrigger: validateTrigger ? 'onChange' : '',
																	initialValue: picture.task
																})(
																	<Select
																		dropdownClassName="custom-select-option"
																		className="custom-select-multiple"
																		getPopupContainer={() => document.getElementById('DropdownFixPostion')}
																		mode="multiple"
																		labelInValue={false}
																		tokenSeparators={[' ', ',']}
																		// value={picture.task}
																		onChange={(value) => this.onSelectChange('task', value)}
																	>
																		{task && task.length > 0 && task.map((key) => {
																			return (
																				<Option key={key.$key} value={key.name}>{key.name}</Option>
																			);
																		})}
																	</Select>
																)}
															</Form.Item>
															:
															<Form.Item label="Task">
																{getFieldDecorator('task', {
																	validateTrigger: validateTrigger ? 'onChange' : ''
																})(
																	<Input disabled={true} />
																)}
															</Form.Item>
														}
													</Col>
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Type">
															{getFieldDecorator('type', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.type,
															})(
																<Radio.Group onChange={(e) => this.onInputChange('type', e.target.value)}>
																	<Radio value="rigDown">Rig Down</Radio>
																	<Radio value="rigUp">Rig Up</Radio>
																</Radio.Group>
															)}
														</Form.Item>
													</Col>

													{/* Canned Messages */}
													{!refreshCannedMsg ?
														<Col md={24} xl={12} id="DropdownFixPostion">
															<Form.Item label="Rig Milestones (Choose Canned Message)">
																{getFieldDecorator('cannedMsg', {
																	validateTrigger: validateTrigger ? 'onChange' : '',
																	initialValue: picture.cannedMsg
																})(
																	<Select
																		dropdownClassName="custom-select-option"
																		className="custom-select-multiple"
																		// getPopupContainer={() => document.getElementById('DropdownFixPostion')}
																		mode="multiple"
																		labelInValue={false}
																		tokenSeparators={[' ', ',']}
																		onChange={(value) => this.onSelectChange('cannedMsg', value)}
																	>
																		{picture.type === 'rigDown' ? cannedList.length > 0 && cannedList.map((key) => {
																			return (
																				<Option key={key.name} value={key.name}>{key.name}</Option>
																			);
																		})
																			:
																			cannedListUp.length > 0 && cannedListUp.map((key) => {
																				return (
																					<Option key={key.name} value={key.name}>{key.name}</Option>
																				);
																			})
																		}
																	</Select>
																)}
															</Form.Item>
														</Col>
														: <Col md={24} xl={12}></Col>
													}
													{picture.entries && picture.entries.length > 0 ?
														<>
															<Col md={24} xl={12} span={24}>
																<Form.Item label="Description / Notes">
																	<IoIosAddCircleOutline size={30} onClick={() => {
																		this.setState({ addDescription: !addDescription });
																		this.setState({ time: moment().format('YYYY-MM-DDTHH:mm') });
																	}} />
																</Form.Item>
																{addDescription === true &&
																	<>
																		<TimePicker
																			showTime
																			format="HH:mm"
																			// onChange={(e) => this.onUpdateInput('time', -1, e)}
																			onChange={(e) => this.setState({ time: moment(e).format('YYYY-MM-DDTHH:mm') })}
																			style={{ width: '100%' }}
																			value={time && time !== '' ? moment(time) : moment()}
																		/>
																		<Input value={desc} onChange={(e) => this.setState({ desc: e.target.value })} />
																		<Button type="primary" disabled={isEmpty(desc)} onClick={() => this.handleDescription()}>Add</Button>
																	</>
																}
															</Col>
															<Col md={24} xl={12} span={24}>
																{picture.entries.map((entry, index) => {
																	return (
																		<div key={index}>
																			<TimePicker
																				showTime
																				format="HH:mm"
																				onChange={(e) => this.onUpdateInput('time', index, e)}
																				style={{ width: '100%' }}
																				// value={entry.time}
																				value={entry.time && entry.time !== '' ? moment(entry.time) : null}
																			/>
																			<Input value={entry.description} onChange={(e) => this.onUpdateInput('description', index, e.target.value)} />
																		</div>
																	);
																})}
															</Col>
														</>
														:
														<Col md={24} xl={12} span={24}>
															<Form.Item label="Description / Notes">
																<IoIosAddCircleOutline size={30} onClick={() => {
																	this.setState({ addDescription: !addDescription });
																	this.setState({ time: moment().format('YYYY-MM-DDTHH:mm') });
																}} />
																{addDescription === true &&
																	<>
																		<TimePicker
																			showTime
																			format="HH:mm"
																			// onChange={(e) => this.onUpdateInput('time', -1, e)}
																			onChange={(e) => this.setState({ time: moment(e).format('YYYY-MM-DDTHH:mm') })}
																			style={{ width: '100%' }}
																			value={time && time !== '' ? moment(time) : moment()}
																		/>
																		<Input value={desc} onChange={(e) => this.setState({ desc: e.target.value })} />
																		<Button type="primary" disabled={isEmpty(desc)} onClick={() => this.handleDescription()}>Add</Button>
																	</>
																}
																<>
																	<TimePicker
																		showTime
																		format="HH:mm"
																		onChange={(e) => this.onUpdateInput('time', index, e)}
																		style={{ width: '100%' }}
																		value={picture.time && picture.time !== '' ? moment(picture.time) : moment()}
																	/>
																	<Input value={picture.description} onChange={(e) => this.onInputChange('description', e.target.value)} />
																</>
															</Form.Item>
														</Col>
													}
													<Col md={24} xl={12} span={24}>
														<Form.Item label="Lessons Learned">
															{getFieldDecorator('lessons', {
																validateTrigger: validateTrigger ? 'onChange' : '',
																initialValue: picture.lessons,
															})(
																<Input.TextArea onChange={(e) => this.onInputChange('lessons', e.target.value)} />
															)}
														</Form.Item>
													</Col>
													{/* More than one dedcripiton entries */}
													{/* {picture.entries && picture.entries.length > 0 &&
														<Col md={24} xl={12} span={24}>
															{picture.entries.map((entry, index) => {
																return (
																	<div key={index}>
																		<p>{entry.time}</p>
																		<p>{entry.description}</p>
																	</div>
																);
															})}
														</Col>
													} */}
													{!isEmpty(picture.lessons) &&
														<Col md={24} xl={12} span={24}>
															<Form.Item label="Corrective Measures">
																{getFieldDecorator('measures', {
																	validateTrigger: validateTrigger ? 'onChange' : '',
																	initialValue: picture.measures,
																})(
																	<Input.TextArea onChange={(e) => this.onInputChange('measures', e.target.value)} />
																)}
															</Form.Item>
														</Col>
													}
												</Row>
												{submitted && <Alert message="Please upload image" type="error" />}
												<Form.Item className="button-section">
													<Button type="primary" htmlType="submit" size="large" className="submitBtn">
														Update
													</Button>
												</Form.Item>
											</Form>
										</div>
									</div>
									{isModalVisible &&
										<Modal className="send-mail-modal" title="Send Mail Details" visible={isModalVisible} onOk={(e) => this.handleOk(e)} onCancel={() => this.handleCancel()}>
											<Form className="theme-form" layout="vertical">
												<Form.Item label="Message">
													{getFieldDecorator('emailDetails.message', {
														validateTrigger: validateTrigger ? 'onChange' : '',
														rules: [
															{ required: true, message: 'Please enter message for sending email' }
														],
														initialValue: emailDetails.message,
													})(
														<Input onChange={(e) => this.onValueChanges('message', e.target.value)} />
													)}
												</Form.Item>
												<Form.Item label="Subject">
													{getFieldDecorator('emailDetails.title', {
														validateTrigger: validateTrigger ? 'onChange' : '',
														rules: [
															{ required: true, message: 'Please enter subject for sending email' }
														],
														initialValue: emailDetails.title,
													})(
														<Input onChange={(e) => this.onValueChanges('title', e.target.value)} />
													)}
												</Form.Item>
												<Form.Item label="To">
													{getFieldDecorator('emailDetails.users', {
														validateTrigger: validateTrigger ? 'onChange' : '',
														rules: [
															{ required: true, message: 'Please enter email address' },
															{ type: 'email', message: 'Invalid email address' },
														],
														initialValue: emailDetails.users,
													})(
														<Input onChange={(e) => this.onValueChanges('users', e.target.value)} />
													)}
												</Form.Item>
											</Form>
										</Modal>
									}
									{isDelete === true &&
										<Modal className='send-mail-modal' title='Delete Pusher Pic' visible={isDelete} onOk={() => this.confirmDelete()} onCancel={() => this.onDeletePic()}>
											<p className='text-center no-mb'><b>Are you sure, you want to delete this pic ?</b></p>
										</Modal>
									}
									{addImage === true &&
										<Modal className='send-mail-modal' title='Add Pusher Pic' visible={addImage} onOk={() => this.confirmAddImage()} onCancel={() => this.toggleAddImage()}>
											{imageAction === 'add' ?
												<p className='text-center no-mb'><b>Are you sure, you want to add this pic ?</b></p>
												:
												<p className='text-center no-mb'><b>Are you sure, you want to update this pic ?</b></p>
											}
										</Modal>
									}
								</div>
							</div>
						);
					})}
				</div>
				{/* <footer className='reportFooter site_footer'>
					<p>Copyright 2018 - all rights reserved by RigMoveApp</p>
				</footer> */}
			</div>
		);
	}
}


const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(EditPusher)));
