import React from 'react';
import { Route, Switch, Redirect, } from 'react-router-dom';
import Login from '../auth/login';
import Dashboard from '../pages/dashboard';
import Rig from '../pages/rig';
import Report from '../pages/report/report';
// import JSAList from '../pages/jsa/jsa-list/jsa-list';
// import JSA from '../pages/jsa/jsa';
// import Companies from '../pages/companies/companies';
// import Assests from '../assets/assets';
import PicListing from '../pages/pic-list/pic-list';
import ReportsData from '../pages/report/reportsData';
import JASListing from '../pages/jsa/jas-listing/jas-listing';
import AssetsList from '../pages/assets/assets-list';
import PrivateRoute from './protectedRoute';
import Home from '../pages/home/home';
import AddLOSEmployee from '../pages/users/LOS_Employee/addLOS';
import AddRig from '../pages/rig/add-rig';
import EditRig from '../pages/rig/edit-rig';
import EditRigNext from '../pages/rig/edit-rig-next';
import EditRigOptional from '../pages/rig/edit-rig-optional';
import EditAsset from '../pages/assets/edit-asset';
import EditPic from '../pages/pic/edit-pic';
import EditReport from '../pages/report/edit-report';
import LoadList from '../pages/rig/load-list';
import Loads from '../pages/rig/load-list/loads';
import LoadReports from '../pages/rig/load-list/load-report';
import LoadReportList from '../pages/rig/load-list/load-report-list';
import Users from '../pages/users';
import AddJSA from '../pages/jsa/add-jsa';
import PusherReportsList from '../pages/truck-pusher/pusher-reports';
import LOSReport from '../pages/report/report-types/los-report';
import SmartGroups from '../pages/users/groups';
import SmartGroupDetails from '../pages/users/groups/group-details';
import EditPusher from '../pages/truck-pusher/pusher-pics';
import JSAReport from '../pages/jsa/jsa-report';
import CreateReport from '../pages/rig/create-report';
import Permissions from '../pages/permissions';
import IndividualTruckLoads from '../pages/individual-truck-loads';
import PusherReport from '../pages/report/report-types/pusher-report';
import { connect } from 'react-redux';
import { checkAccess, checkPermissions, checkWritePermissions } from '../utils/native';

const RouterComponent = (props) => {
	// const isLoggedin = localStorage.getItem('user') ? true : false;
	const isLoggedin = props.auth.isLoggedIn === true ? true : false;
	const isAdmin = localStorage.getItem('admin') ? true : false;

	return (
		<Switch>
			<Route exact={true} path='/' render={() => (
				(isLoggedin) ? isAdmin ? <Redirect to='/dashboard' /> : <Redirect to='/rigs' /> : <Redirect to='/signin' />
			)} />
			<Route exact={true} path='/signin' render={() => (
				isLoggedin ? isAdmin ? <Redirect to='/dashboard' /> : <Redirect to='/rigs' /> : <Login />
			)} />

			<PrivateRoute path='/dashboard' authed={isLoggedin && isAdmin} isPermitted={true} component={Home} />
			<PrivateRoute path='/pusher/:id' authed={isLoggedin} isPermitted={checkPermissions(props, 'reports')} component={PusherReport} />
			<PrivateRoute path='/pusher' authed={isLoggedin && isAdmin} isPermitted={checkPermissions(props, 'reports')} component={PusherReportsList} />
			<PrivateRoute path='/rigs/edit/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'rigs')} component={EditRig} />
			<PrivateRoute path='/rigs/next/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'rigs')} component={EditRigNext} />
			<PrivateRoute path='/rigs/optional/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'rigs')} component={EditRigOptional} />
			<PrivateRoute path='/rigs/add' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'rigs')} component={AddRig} />
			<PrivateRoute path='/rigs/create-report/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'reports')} component={CreateReport} />
			<PrivateRoute path='/rigs/:id' authed={isLoggedin} isPermitted={checkPermissions(props, 'rigs')} component={Rig} />
			<PrivateRoute path='/rigs' authed={isLoggedin} isPermitted={checkPermissions(props, 'rigs')} component={Dashboard} />
			<PrivateRoute path='/pic/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'pusher')} component={EditPusher} />
			<PrivateRoute path='/pics/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'rigPics')} component={EditPic} />
			<PrivateRoute path='/pics' authed={isLoggedin} isPermitted={checkPermissions(props, 'rigPics') || checkPermissions(props, 'pusher')} component={PicListing} />
			<PrivateRoute path='/reports' authed={isLoggedin} isPermitted={checkPermissions(props, 'reports')} component={ReportsData} />
			{/* <PrivateRoute path='/jsaList/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'losSafety')} component={JSAList} /> */}
			{/* <PrivateRoute path='/jsaList/:id/:i' authed={isLoggedin} isPermitted={checkPermissions(props, 'losSafety')} component={JSA} /> */}
			<PrivateRoute path='/jsaList/:id' authed={isLoggedin} isPermitted={checkPermissions(props, 'losSafety')} component={AddJSA} />
			<PrivateRoute path='/jsaList' authed={isLoggedin} isPermitted={checkPermissions(props, 'losSafety')} component={JASListing} />
			<PrivateRoute path='/jsaReport' authed={isLoggedin} isPermitted={checkPermissions(props, 'losSafety')} component={JSAReport} />
			<PrivateRoute path='/assets/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'assets')} component={EditAsset} />
			<PrivateRoute path='/assets' authed={isLoggedin} isPermitted={checkPermissions(props, 'assets')} component={AssetsList} />
			<PrivateRoute path='/report/:report/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'reports')} component={EditReport} />
			<PrivateRoute path='/report/:id' authed={isLoggedin} isPermitted={checkPermissions(props, 'reports')} component={Report} />
			<PrivateRoute path='/individual_truck_loads/:id' authed={isLoggedin} isPermitted={checkPermissions(props, 'reports')} component={Report} />
			<PrivateRoute path='/los_report/:id' authed={isLoggedin} isPermitted={checkPermissions(props, 'reports')} component={LOSReport} />
			{/* <PrivateRoute path='/companies/:id' authed={isLoggedin} component={Companies} /> */}
			{/* <PrivateRoute path='/asset/:id' authed={isLoggedin} component={Assests} /> */}
			<PrivateRoute path='/groups/:id/:group_id' authed={isLoggedin} isPermitted={checkPermissions(props, 'smartGroups')} component={SmartGroupDetails} />

			{/* For Load list */}
			<PrivateRoute path='/load_list/:id' authed={isLoggedin && isAdmin} isPermitted={checkPermissions(props, 'reports')} component={Loads} />
			<PrivateRoute path='/load_list' authed={isLoggedin && isAdmin} isPermitted={checkPermissions(props, 'reports')} component={LoadList} />
			<PrivateRoute path='/load_report/:id' authed={isLoggedin && isAdmin} isPermitted={checkPermissions(props, 'reports')} component={LoadReports} />
			<PrivateRoute path='/load_report' authed={isLoggedin && isAdmin} isPermitted={checkPermissions(props, 'reports')} component={LoadReportList} />

			<PrivateRoute path='/users/:users/add' authed={isLoggedin && isAdmin} isPermitted={checkWritePermissions(props, 'users')} component={AddLOSEmployee} />
			<PrivateRoute path='/users/:users/edit/:id' authed={isLoggedin} isPermitted={checkWritePermissions(props, 'users')} component={AddLOSEmployee} />
			<PrivateRoute path='/users' authed={isLoggedin} isPermitted={checkPermissions(props, 'users')} component={Users} />
			<PrivateRoute path='/groups' authed={isLoggedin} isPermitted={checkPermissions(props, 'smartGroups')} component={SmartGroups} />
			<PrivateRoute path='/permissions' authed={isLoggedin} isPermitted={checkAccess(props)} component={Permissions} />
			<PrivateRoute path='/individual_truck_loads' authed={isLoggedin} isPermitted={checkPermissions(props, 'individualLoads')} component={IndividualTruckLoads} />
		</Switch>
	);
};

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(RouterComponent);