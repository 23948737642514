import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Dropdown, Menu, Row, Col, DatePicker, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import firebase from '../../../services/firebase';
import { BiArrowBack } from 'react-icons/bi';
import loader from '../../../assets/imgs/loader.gif';
import moment from 'moment';
import { checkWritePermissions } from '../../../utils/native';
import { isEmpty } from '../../../utils/activity-logs';

class EditRig extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(this.props, 'rigs'),
			assets: [],
			data: [],
			load: false,
			validateTrigger: false,
			id: props.match.params.id,
			rig: {},
			menu: (
				<Menu>
					<Menu.Item key="In-field" onClick={(value) => this.onValueChange('moveType', value.key)}>In-field</Menu.Item>
					<Menu.Item key="Out-field" onClick={(value) => this.onValueChange('moveType', value.key)}>Out-field</Menu.Item>
					<Menu.Item key="Walk" onClick={(value) => this.onValueChange('moveType', value.key)}>Walk</Menu.Item>
				</Menu>
			),
			menuStatus: (
				<Menu>
					<Menu.Item key="Active" onClick={(value) => this.onValueChange('status', value.key)}>Active</Menu.Item>
					<Menu.Item key="Scheduled" onClick={(value) => this.onValueChange('status', value.key)}>Scheduled</Menu.Item>
					<Menu.Item key="Completed" onClick={(value) => this.onValueChange('status', value.key)}>Completed</Menu.Item>
					<Menu.Item key="Canceled" onClick={(value) => this.onValueChange('status', value.key)}>Cancelled</Menu.Item>
				</Menu>
			),
			isLoading: false,
			currentUser: props.auth.currentUser,
			rigTypeMenu: (
				<Menu>
					<Menu.Item key="Apex" onClick={(value) => this.onInputChange('type', value.key)}>Apex</Menu.Item>
					<Menu.Item key="BOSS" onClick={(value) => this.onInputChange('type', value.key)}>BOSS</Menu.Item>
					<Menu.Item key="Box on Box" onClick={(value) => this.onInputChange('type', value.key)}>Box on Box</Menu.Item>
					<Menu.Item key="Conventional" onClick={(value) => this.onInputChange('type', value.key)}>Conventional</Menu.Item>
					<Menu.Item key="Down-Hole" onClick={(value) => this.onInputChange('type', value.key)}>Down-Hole</Menu.Item>
					<Menu.Item key="F-Rigs" onClick={(value) => this.onInputChange('type', value.key)}>F-Rigs</Menu.Item>
					<Menu.Item key="Flex 3" onClick={(value) => this.onInputChange('type', value.key)}>Flex 3</Menu.Item>
					<Menu.Item key="Flex 3S" onClick={(value) => this.onInputChange('type', value.key)}>Flex 3S</Menu.Item>
					<Menu.Item key="Flex 3W" onClick={(value) => this.onInputChange('type', value.key)}>Flex 3W</Menu.Item>
					<Menu.Item key="Flex 4" onClick={(value) => this.onInputChange('type', value.key)}>Flex 4</Menu.Item>
					<Menu.Item key="Flex 5" onClick={(value) => this.onInputChange('type', value.key)}>Flex 5</Menu.Item>
					<Menu.Item key="M-Rigs" onClick={(value) => this.onInputChange('type', value.key)}>M-Rigs</Menu.Item>
					<Menu.Item key="NOV" onClick={(value) => this.onInputChange('type', value.key)}>NOV</Menu.Item>
					<Menu.Item key="Other" onClick={(value) => this.onInputChange('type', value.key)}>Other</Menu.Item>
					<Menu.Item key="Percussion" onClick={(value) => this.onInputChange('type', value.key)}>Percussion</Menu.Item>
					<Menu.Item key="RIG STYLE" onClick={(value) => this.onInputChange('type', value.key)}>RIG STYLE</Menu.Item>
					<Menu.Item key="ROCKET" onClick={(value) => this.onInputChange('type', value.key)}>ROCKET</Menu.Item>
					<Menu.Item key="Rotary" onClick={(value) => this.onInputChange('type', value.key)}>Rotary</Menu.Item>
					<Menu.Item key="S-Rigs" onClick={(value) => this.onInputChange('type', value.key)}>S-Rigs</Menu.Item>
					<Menu.Item key="SLING SHOT" onClick={(value) => this.onInputChange('type', value.key)}>SLING SHOT</Menu.Item>
					<Menu.Item key="Super Single" onClick={(value) => this.onInputChange('type', value.key)}>Super Single</Menu.Item>
					<Menu.Item key="Tele-Double" onClick={(value) => this.onInputChange('type', value.key)}>Tele-Double</Menu.Item>
					<Menu.Item key="Triple Box" onClick={(value) => this.onInputChange('type', value.key)}>Triple Box</Menu.Item>
					<Menu.Item key="WOLFSLAYER" onClick={(value) => this.onInputChange('type', value.key)}>WOLFSLAYER</Menu.Item>
					<Menu.Item key="Workover" onClick={(value) => this.onInputChange('type', value.key)}>Workover</Menu.Item>
					<Menu.Item key="X-Rigs" onClick={(value) => this.onInputChange('type', value.key)}>X-Rigs</Menu.Item>
				</Menu>
			)
		};
	}

	componentDidMount() {
		this.getSelectedRig();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'rigs') });
		}
		const { rigsList } = this.props.rig;
		if (prevProps.rig.rigsList.length !== rigsList.length) {
			this.getSelectedRig();
		}
	}

	getSelectedRig() {
		this.setState({ isLoading: true });
		const { rigsList } = this.props.rig;
		const temp = rigsList.find((item) => item.$key === this.state.id);
		if (temp) {
			this.setState({ rig: {...temp, releaseDate: temp.releaseDate.replace('Z', '')}, isLoading: false });
		}
	}

	onInputChange(key, value) {
		const { rig } = this.state;
		rig[key] = value;
		this.setState({ ...rig });

		if (rig.estDays && rig.actualDaysOfRigmove && rig.estDays !== '' && rig.actualDaysOfRigmove !== '') {
			rig.estimatedVsActual = rig.actualDaysOfRigmove - rig.estDays;
		} else {
			rig.estimatedVsActual = 0;
		}


		if (rig.actualDaysOfRigmove && rig.actualDaysOfRigmove !== '') {
			if ((rig.NptHours) && (rig.NptHours !== 0)) {
				rig.WoNptDays = rig.actualDaysOfRigmove - rig.NptDays;
				rig.WoNptHours = 24 - rig.NptHours;

				if (((rig.NptDays === 0) || (!rig.NptDays)) && (rig.NptHours !== 0)) {
					rig.NptDays = 0;
					rig.WoNptDays = rig.actualDaysOfRigmove;
					rig.WoNptHours = 24 - rig.NptHours;
				}
				if (rig.WoNptDays >= 1) {
					rig.WoNptDays -= 1;
				}
				if ((rig.WoNptDays > 0) && (rig.WoNptDays < 1)) {
					rig.WoNptHours = 0;
					rig.WoNptDays += 1;
				}
			} else {
				rig.WoNptDays = rig.actualDaysOfRigmove - rig.NptDays;
				rig.WoNptHours = 0;
			}
		}
		if ((rig.NptHours === 0)) {
			rig.NptHours = 0;
			rig.WoNptHours = 0;
		}
		if ((rig.NptDays === 0) && (rig.NptHours === 0)) {
			rig.NptDays = 0;
			rig.WoNptDays = 0;
			rig.NptHours = 0;
			rig.WoNptHours = 0;
		}
	}

	onChange(key, value) {
		const { rig } = this.state;
		// rig['releaseDate'] = moment(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
		// rig[key] = moment(value).format('YYYY-MM-DDTHH:mm:ss[Z]');
		rig[key] = isEmpty(value) ? '' : moment(value).format('YYYY-MM-DDTHH:mm:ss');
		this.setState({ ...rig });
	}

	onValueChange(key, value) {
		const oldRig = this.state.rig;
		key === 'moveType' ? oldRig.moveType = value : oldRig.status = value;
		this.setState({ rig: oldRig });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form } = this.props;
		const { rig } = this.state;

		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setState({ errorMessage: '' });
				rig.updatedAt = moment().valueOf();
				rig.updatedBy = localStorage.getItem('user');

				const db = firebase.firestore();
				await db.doc(`rigs/${this.state.id}`).update(rig)
					// .then(() => this.props.history.push(`/rigs/next/${rig.$key}`))
					.then(() => this.props.history.push(`/rigs/optional/${rig.$key}`))
					.catch(() => { });
			}
		});
	}

	render() {
		const { validateTrigger, rig, menu, menuStatus, isLoading, rigTypeMenu } = this.state;
		const { form: { getFieldDecorator } } = this.props;
		const { Option } = Select;

		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
		return (
			<div className="edit-rig-area edit-rig-asset-part">
				<h4 className="card-title no-mb icon">
					<BiArrowBack
						size={40}
						onClick={() => this.props.history.push('/rigs')}
					/>
					Edit Rig #{rig.number}
				</h4>
				<div className="card-body">
					<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form">
						<Row>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Job Number">
									{getFieldDecorator('jobNumber', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter job number' }
										],
										initialValue: rig.jobNumber,
									})(
										<Input onChange={(e) => this.onInputChange('jobNumber', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Name">
									{getFieldDecorator('name', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter rig name' }
										],
										initialValue: rig.name
									})(
										<Input onChange={(e) => this.onInputChange('name', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig ID (number)">
									{getFieldDecorator('number', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter rig number' }
										],
										initialValue: rig.number
									})(
										<Input onChange={(e) => this.onInputChange('number', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Drilling Rig Manager / Tool Pusher">
									{getFieldDecorator('manager', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.manager
									})(
										<Input onChange={(e) => this.onInputChange('manager', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Phone">
									{getFieldDecorator('phoneNumber', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.phoneNumber
									})(
										<Input onChange={(e) => this.onInputChange('phoneNumber', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Email">
									{getFieldDecorator('email', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ type: 'email', message: 'Invalid email address' },
										],
										initialValue: rig.email
									})(
										<Input autoComplete="email" onChange={(e) => this.onInputChange('email', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							{/* <Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Superintendent">
									{getFieldDecorator('superintendent', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.superintendent
									})(
										<Input onChange={(e) => this.onInputChange('superintendent', e.target.value)} />,
									)}
								</Form.Item>
							</Col> */}
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Rig Release Date">
									{getFieldDecorator('releaseDate', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.releaseDate !== '' ? moment(rig.releaseDate) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											style={{ width: '100%' }}
											showTime
											format="DD-MMM-YY HH:mm"
											onChange={(e) => this.onChange('releaseDate', e)}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Old Location/Well Name">
									{getFieldDecorator('oldLocationName', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.oldLocationName
									})(
										<Input onChange={(e) => this.onInputChange('oldLocationName', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							{/* <Col md={24} xl={12} span={24}>
								<Form.Item label="Current Location GPS">
									{getFieldDecorator('oldLocation', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.oldLocation
									})(
										<Input onChange={(e) => this.onInputChange('oldLocation', e.target.value)} />,
									)}
								</Form.Item>
							</Col> */}
							<Col md={24} xl={12} span={24}>
								<Form.Item label="New Location/Well Name">
									{getFieldDecorator('newLocationName', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.newLocationName
									})(
										<Input onChange={(e) => this.onInputChange('newLocationName', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							{/* <Col md={24} xl={12} span={24}>
								<Form.Item label="New Location GPS">
									{getFieldDecorator('newLocation', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.newLocation
									})(
										<Input onChange={(e) => this.onInputChange('newLocation', e.target.value)} />,
									)}
								</Form.Item>
							</Col> */}
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig Operator">
									{getFieldDecorator('operator', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.operator
									})(
										<Input onChange={(e) => this.onInputChange('operator', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Operator - Company Man">
									{getFieldDecorator('operatorName', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.operatorName
									})(
										<Input onChange={(e) => this.onInputChange('operatorName', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Operator - Company Man Phone">
									{getFieldDecorator('operatorPhone', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.operatorPhone
									})(
										<Input onChange={(e) => this.onInputChange('operatorPhone', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Operator - Company Man Email">
									{getFieldDecorator('operatorEmail', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ type: 'email', message: 'Invalid email address' },
										],
										initialValue: rig.operatorEmail
									})(
										<Input autoComplete="email" onChange={(e) => this.onInputChange('operatorEmail', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							{/* <Col md={24} xl={12} span={24}>
								<Form.Item label="Operator DSM">
									{getFieldDecorator('operatorEngineer', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.operatorEngineer
									})(
										<Input onChange={(e) => this.onInputChange('operatorEngineer', e.target.value)} />,
									)}
								</Form.Item>
							</Col> */}
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Rig Type">
									{getFieldDecorator('type', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.type
									})(
										<Dropdown
											getPopupContainer={() => document.getElementById('DropdownFixPostion')}
											overlayClassName="custom-dropdown-option"
											className="custom-dropdown-part"
											overlay={rigTypeMenu}
											placement="bottomCenter"
											arrow
										>
											<Button size="large">{rig.type}</Button>
										</Dropdown>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Move Type">
									{getFieldDecorator('moveType', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.moveType
									})(
										<Dropdown overlayClassName="custom-dropdown-option" className="custom-dropdown-part" overlay={menu} placement="bottomCenter" arrow>
											<Button size="large" style={{ textAlign: 'left', height: '50px' }}>{rig.moveType}</Button>
										</Dropdown>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Status">
									{getFieldDecorator('status', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.status
									})(
										<Dropdown overlayClassName="custom-dropdown-option" className="custom-dropdown-part" overlay={menuStatus} placement="bottomCenter" arrow>
											<Button size="large" style={{ textAlign: 'left', height: '50px' }}>{rig.status !== 'Canceled' ? rig.status : 'Cancelled'}</Button>
										</Dropdown>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Est. Miles to move">
									{getFieldDecorator('estMiles', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.estMiles,

									})(
										<Input type="number" onChange={(e) => this.onInputChange('estMiles', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Est. days to move">
									{getFieldDecorator('estDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.estDays
									})(
										<Input type="number" onChange={(e) => this.onInputChange('estDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Actual move days">
									{getFieldDecorator('actualDaysOfRigmove', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.actualDaysOfRigmove,
									})(
										<Input type="number" onChange={(e) => this.onInputChange('actualDaysOfRigmove', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Estimated vs Actual">
									{getFieldDecorator('estimatedVsActual', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.estimatedVsActual
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('estimatedVsActual', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Weather conditions">
									{getFieldDecorator('weather', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.weather,
									})(
										<Select
											getPopupContainer={() => document.getElementById('DropdownFixPostion')}
											size="large"
											mode="multiple"
											dropdownClassName="custom-select-option"
											className="custom-select-multiple"
											onChange={(value) => this.onInputChange('weather', value)}
										>
											<Option value="Hot/Dry">Hot/Dry</Option>
											<Option value="Sunny/Clear">Sunny/Clear</Option>
											<Option value="Partially Cloudy">Partially Cloudy</Option>
											<Option value="Cloudy">Cloudy</Option>
											<Option value="Overcast">Overcast</Option>
											<Option value="Windy">Windy</Option>
											<Option value="Rain">Rain</Option>
											<Option value="Drizzle">Drizzle</Option>
											<Option value="Foggy">Foggy</Option>
											<Option value="Snow">Snow</Option>
											<Option value="Stormy">Stormy</Option>
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion"></Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<p style={{ color: 'red' }}>Start Rig Move Clock with<span>1st Safety Meeting, 1st Truck Load or 1st Crane Pick</span></p>
								<Form.Item label="Start Rig Move Clock">
									{getFieldDecorator('startRigClock', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.startRigClock && rig.startRigClock !== '' ? moment(rig.startRigClock) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('startRigClock', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<p style={{ color: 'red' }}>Stop Rig Move Clock with Last Load or Last Crane Pick</p>
								<Form.Item label="Stop Rig Move Clock">
									{getFieldDecorator('stopRigClock', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.stopRigClock && rig.stopRigClock !== '' ? moment(rig.stopRigClock) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('stopRigClock', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
						<Form.Item className="button-section">
							<Button htmlType="submit" size="large" className="submitBtn">
								Save & Continue
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(EditRig)));