import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';
import loader from '../../../assets/imgs/loader.gif';
import moment from 'moment';
import { checkWritePermissions } from '../../../utils/native';

const { Column } = Table;

class LoadList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			isLoading: false,
			rigs: [],
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
		const { rigsList } = this.props.rig;
		if (prevProps.rig.rigsList.length !== rigsList.length) {
			this.getRigs();
		}
	}

	componentDidMount() {
		this.getRigs();
	}

	getRigs() {
		this.setState({ Loader: true });
		const { rigsList } = this.props.rig;
		this.setState({ rigs: rigsList.filter((item) => item.archive === false), Loader: false });
	}

	handleView(key) {
		this.props.history.push(`/load_list/${key}`);
	}

	render() {
		const { rigs, Loader } = this.state;

		if (Loader === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="card-body">
				{rigs.length > 0 &&
					<Table dataSource={rigs} rowKey={(record, index) => record.uid + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
						<Column title="Name" dataIndex="name" key="name" />
						<Column title="Number" dataIndex="number" key="number" />
						<Column title="Status" dataIndex="status" key="status" />
						<Column title="Loadcount" dataIndex="loadcount" key="loadcount" />
						<Column title="Release Date" dataIndex="releaseDate" render={(_, record) => <p>{record.releaseDate && moment(record.releaseDate).format('MMM DD, YYYY')}</p>} />
						<Column title="Move Type" dataIndex="moveType" key="moveType" />
						<Column title="View" dataIndex="View" render={(_, record) =>
							this.state.rigs.length >= 1 ? (
								<p className="table-action-link box-link" onClick={() => this.handleView(record.$key)}>View</p>
							) : null}
						/>
					</Table>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(LoadList));
