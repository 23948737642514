import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Icon, Input, Row, Select, Table, Modal, Popconfirm } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from 'react-icons/io';
import { AiOutlineEdit } from 'react-icons/ai';
import firebase from '../../../services/firebase';
import loader from '../../../assets/imgs/loader.gif';
import dragmoveImg from '../../../assets/imgs/draggable-dots.png';
import './groups.css';
import { isEmpty } from '../../../utils/activity-logs';
import { checkDeletePermissions, checkWritePermissions } from '../../../utils/native';

class SmartGroups extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'smartGroups'),
			isDeletePermitted: checkDeletePermissions(props, 'smartGroups'),
			addSection: '',
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false,
			user: props.auth.currentUser,
			admins: [],
			showModal: false,
			deleteSegmentId: '',
			isLoading: false,
			addSegmentTable: false,
			editGroup: false,
			editIndex: '',
			editGroupValue: '',
			showAllAccordion: false,
			addSegments: [
				{
					selected: true,
					groups: [
						{
							name: '',
							connected: '',
							createdBy: '',
							administratedBy: []
						}
					]
				},
			],
			groupCols: [
				{
					title: 'Group Name',
					dataIndex: 'name',
					key: 'name',
					render: (_, record) => record.name
				},
				{
					title: 'Connected',
					dataIndex: 'connected',
					key: 'connected',
					render: (_, record) => record.administeredBy && record.joinedUsers && record.administeredBy.length + record.joinedUsers.length
				},
				{
					title: 'Created By',
					dataIndex: 'createdBy',
					key: 'createdBy',
					render: (_, record) => record.createdByUser
				},
				{
					title: 'Administrated By',
					dataIndex: 'administeredUsers',
					key: 'administeredUsers',
					render: (_, record) => record.administeredUsers && this.avatarFunction(record),
				},
				// {
				// 	title: 'View',
				// 	dataIndex: 'view',
				// 	key: 'view',
				// 	render: (_, record, index) => record && (
				// 		<p onClick={() => this.viewGroup(record, index)}>View</p>
				// 	)
				// },
			],
			groupAdminCols: [
				{
					title: 'Group Name',
					dataIndex: 'name',
					key: 'name',
					render: (_, record, index) => {
						const { editIndex,editGroupValue } = this.state;
						return(
							<>
								{
									(editIndex===index && editGroupValue === record) ?
										<Input
											autoFocus
											className='group-input'
											value={editGroupValue && editGroupValue.name}
											placeholder='Edit group'
											onChange={(e) => {
												this.handleEditGroupChanges('name', e.target.value, index);
											}}
											onBlur={() => {
												this.updateGroup(this.getSegmentIndex(record));
											}}
											onKeyDown={(event) => {
												if (event.key === 'Enter') {
													this.updateGroup(this.getSegmentIndex(record));
												}
											}}
										/>
										: (<label
											className='group-name-txt'> { record.name }
											<span
												className='group-name-edit-icon'
												onClick={() => {
													this.handleUpdate(record, index);
												}}>
												<AiOutlineEdit />
											</span>
										</label>)
								}
							</>
						);
					}
				},
				{
					title: 'Connected',
					dataIndex: 'connected',
					key: 'connected',
					render: (_, record) => record.administeredBy && record.joinedUsers && record.administeredBy.length + record.joinedUsers.length
				},
				{
					title: 'Created By',
					dataIndex: 'createdBy',
					key: 'createdBy',
					render: (_, record) => record.createdByUser
				},
				{
					title: 'Administrated By',
					dataIndex: 'administeredUsers',
					key: 'administeredUsers',
					render: (_, record) => record.administeredUsers && this.avatarFunction(record),
				},
				// {
				// 	title: 'View',
				// 	dataIndex: 'view',
				// 	key: 'view',
				// 	render: (_, record, index) => record && (
				// 		<p onClick={() => this.viewGroup(record, index)}>View</p>
				// 	)
				// },
				{
					title: 'Delete',
					dataIndex: 'delete',
					key: 'delete',
					render: (_, record, index) => record && (
						<Popconfirm
							overlayClassName='custom-popconfirm'
							title='Are you sure to delete this group?'
							onConfirm={() => this.handleDelete(record, index)}>
							<a href='true'>Delete</a>
						</Popconfirm>
					)
				},
			],
			searchedSegments: [],
			searchInput: ''
		};
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		this.getAllAdmins();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'smartGroups'), isDeletePermitted: checkDeletePermissions(this.props, 'smartGroups') });
		}
	}

	handleUpdate(key, index) {
		this.setState({
			editGroupValue: key,
			editIndex: index
		});
	}

	async handleDelete(key, index) {
		const { segments } = this.state;

		let segment_index;
		segments.forEach((seg, ind) => {
			if (seg.$key === key.segment_id) {
				segment_index = ind;
				segments[ind].groups[index].archive = true;
			}
		});

		const database = firebase.firestore();
		await database.doc(`segments/${segments[segment_index].$key}`).update(segments[segment_index]);
		this.getSections(false);
	}

	avatarFunction(record) {
		return (
			<div className='avatar-table-part'>
				{record.administeredUsers.map((administeredUsers, index) => {
					return (
						index < 3 ?
							<div className='avatar-table' key={index.toString()}>{administeredUsers}</div> :
							index === 3 &&
							<div className='avatar-table number' key={index.toString()}>+ {record.administeredUsers.length - 3}</div>
					);
				})
				}
			</div>
		);
	}

	getSections = (value) => {
		const { admins } = this.state;

		const db = firebase.firestore();
		const segmentsRef = db.collection('segments').orderBy('createdAt', 'desc');
		segmentsRef.get().then((querySnapshot) => {
			const temp = [];
			this.setState({ isLoading: false });
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (value === true) {
						data.selected = false;
					}
					if (data.archive !== true) {
						if (data.groups && data.groups.length > 0) {
							data.groups.forEach((group, index) => {
								if (group.archive === true) {
									data.groups.splice(index, 1);
								}
							});
							data.groups.map((group) => {
								const administeredUsers = [];
								group.administeredBy.map((administered) => {
									const userIndex = admins.map(admin => admin.uid).indexOf(administered);
									if (admins[userIndex] !== undefined) {
										administeredUsers.push(admins[userIndex].firstName[0].toUpperCase() + admins[userIndex].lastName[0].toUpperCase());
									}
									return administeredUsers;
								});
								group.administeredUsers = administeredUsers;
								//Created By
								const userIndex = admins.map(admin => admin && admin.uid).indexOf(group.createdByUid);
								if (!isEmpty(userIndex) && userIndex !== -1) {
									group.createdByUser = admins[userIndex].firstName + ' ' + admins[userIndex].lastName;
								}
								return group;
							});
						}
						temp.push(data);
					}
				}
			});
			this.setState({ segments: temp });
		});
	}

	getAllAdmins = () => {
		const { usersList } = this.props.auth;
		const admins = usersList.filter((item) => item.userType === 'admin');
		this.setState({ admins });
		this.getSections(true);
	}

	hideShowAllAccordion() {
		const { segments, showAllAccordion } = this.state;
		segments.forEach((seg) => {
			seg.selected = !showAllAccordion;
		});
		this.setState({
			showAllAccordion: !this.state.showAllAccordion,
			segments
		});
	}

	showSelectedAccordion(segment_index) {
		const { segments, showAllAccordion } = this.state;
		segments[segment_index].selected = !segments[segment_index].selected;
		let count = 0;
		segments.forEach((seg) => {
			if (showAllAccordion === false) {
				seg.selected === true && count++;
				if ((count === segments.length)) {
					this.hideShowAllAccordion();
				}
			}
			if (showAllAccordion === true) {
				seg.selected === false && count++;
				if ((count === segments.length)) {
					this.hideShowAllAccordion();
				}
			}
		});
		this.setState({ segments });
	}

	hideShowNewAccordion() {
		const { addSegments } = this.state;
		addSegments[0].selected = !addSegments[0].selected;
		this.setState({ addSegments });
	}

	addSegmentTableBtn() {
		this.setState({
			addSegmentTable: true
		});
	}

	onInputChange(key, value) {
		this.setState({ [key]: value });
	}

	handleGroupChanges(key, value, index) {
		const { admins, segments } = this.state;
		if (key === 'administeredBy') {
			const temp = [];
			admins.forEach((admin) => {
				value.forEach((val) => {
					if (admin.uid === val) {
						temp.push(val);
					}
				});
			});
			segments[index][key] = temp;
			this.setState({ segments });
		} else {
			if (value === '' || value === undefined) {
				const oldSegments = segments;
				delete oldSegments[index]['addGroup'];
				delete oldSegments[index]['administeredBy'];
				delete oldSegments[index]['selectError'];
				this.setState({ segments: oldSegments });
			} else {
				segments[index][key] = value;
				this.setState({ segments });
			}
		}
	}

	// Admin only
	addNewSection = async () => {
		const { segments, addSection, user } = this.state;
		if (addSection !== '') {
			const value = {
				name: addSection,
				order: segments.length,
				createdAt: moment().valueOf(),
				createdByUid: user.uid,
				groups: []
			};
			const oldSegments = segments;
			oldSegments.push(value);

			const database = firebase.firestore();
			await database.collection('segments').add(value);

			this.setState({ segments: oldSegments });
			this.setState({ addSegmentTable: false });
			this.onInputChange('addSection', '');
			this.getSections(false);
		} else {
			alert('Please add section name');
		}
	}

	// Admin only
	addNewGroup = async (segment_index) => {
		const { segments, user } = this.state;

		const oldSegments = segments;
		if (oldSegments[segment_index] && oldSegments[segment_index].administeredBy) {
			const groups = oldSegments[segment_index].groups;
			const value = {
				name: oldSegments[segment_index].addGroup,
				connected: oldSegments[segment_index].administeredBy.length,
				createdAt: moment().valueOf(),
				createdByUid: user.uid,
				joinedUsers: [],
				administeredBy: oldSegments[segment_index].administeredBy,
				segment_id: oldSegments[segment_index].$key
			};
			groups.push(value);
			oldSegments[segment_index].groups = groups;
			delete oldSegments[segment_index]['addGroup'];
			delete oldSegments[segment_index]['administeredBy'];
			delete oldSegments[segment_index]['selectError'];

			const database = firebase.firestore();
			await database.doc(`segments/${oldSegments[segment_index].$key}`).update(oldSegments[segment_index]);
			this.getSections(false);
		} else {
			oldSegments[segment_index]['selectError'] = true;
			this.setState({ segments: oldSegments });
		}
	}

	deleteSegmentConfirm(segment_index) {
		this.setState({ showModal: true, deleteSegmentId: segment_index });
	}

	handleCancel() {
		this.setState({ showModal: false });
	}

	async deleteSegment() {
		const { deleteSegmentId } = this.state;
		const { segments } = this.state;

		if (deleteSegmentId !== undefined) {
			const oldSegments = segments;
			oldSegments[deleteSegmentId].archive = true;
			const database = firebase.firestore();
			await database.doc(`segments/${oldSegments[deleteSegmentId].$key}`).update(oldSegments[deleteSegmentId]);
			// updated locally
			oldSegments.splice(deleteSegmentId, 1);
			this.setState({ segments: oldSegments, deleteSegmentId: '', showModal: false });
		} else {
			this.setState({ addSegmentTable: false, addSection: '', deleteSegmentId: '', showModal: false });
		}
	}

	searchSegment(value) {
		const { segments } = this.state;
		const string = value.toLowerCase();
		const temp = [];

		for (let i = 0; i < segments.length; i++) {
			const data = segments[i].name.toLowerCase().match(string);

			if (data !== null) {
				temp.push(segments[i]);
			}
		}
		this.setState({ searchedSegments: temp, searchInput: value });
	}

	viewGroup(record, index) {
		this.props.history.push(`/groups/${record.segment_id}/${index}`);
	}

	handleEditGroupChanges(key, value) {
		const { editGroupValue } = this.state;

		const oldGroup = editGroupValue;
		if (key === 'name') {
			oldGroup.name = value;
		}
		if (key === 'administeredBy') {
			oldGroup.administeredBy = value;
		}
		this.setState({ editGroupValue: oldGroup });
	}

	async updateGroup(segment_index) {
		const { segments, editGroupValue, editIndex, searchInput, searchedSegments } = this.state;

		const oldSegments = searchInput === '' ? segments : searchedSegments;
		if (editGroupValue.segment_id && editGroupValue.administeredBy.length > 0) {
			const group = oldSegments[segment_index].groups[editIndex];
			group.name = editGroupValue.name;
			group.administeredBy = editGroupValue.administeredBy;
			group.updatedAt = moment().valueOf();

			oldSegments[segment_index].groups[editIndex] = group;
			this.setState({ segments: oldSegments, editGroup: false, editGroupValue: '', editIndex: '' });
			delete oldSegments[segment_index]['selectError'];
			delete oldSegments[segment_index].groups[editIndex]['administeredUsers'];

			const database = firebase.firestore();
			await database.doc(`segments/${oldSegments[segment_index].$key}`).update(oldSegments[segment_index]);
			this.getSections(false);
		} else {
			oldSegments[segment_index]['selectError'] = true;
			this.setState({ segments: oldSegments });
		}
	}

	renderSegments(segments) {
		const { isAdmin, admins, groupCols, showAllAccordion, groupAdminCols, isDeletePermitted } = this.state;
		const { Option } = Select;
		return (
			segments && segments.map((segment, segment_index) => {
				return (
					<div className={showAllAccordion ? 'accordion-custom active' : 'accordion-custom'} key={segment_index.toString()}>
						<h6 className='accordion-head'>
							<div className='left-part'>
								<div className='drag-input' onClick={() => this.showSelectedAccordion(segment_index)}>
									<img className='dragIcon' src={dragmoveImg} alt='drag move' />
									{segment.name}
								</div>
								{isAdmin === true && isDeletePermitted &&
									<Icon type='delete' className='error-text deletIcon' onClick={() => this.deleteSegmentConfirm(segment_index)} />
								}
							</div>
							<div className='group-right-chips'>
								<Icon type='team' />
								<span>{segment.groups.length}</span>
								Groups
							</div>
						</h6>
						{((showAllAccordion === true && segment.selected === true) || segment.selected === true) &&
							<div className='accordion-content'>
								{isAdmin ?
									<Table columns={groupAdminCols} dataSource={segment.groups} rowKey={(record, index) => segment.$key + index} style={{ cursor: 'pointer' }} />
									:
									<Table columns={groupCols} dataSource={segment.groups} rowKey={(record, index) => segment.$key + index} style={{ cursor: 'pointer' }} />
								}
								{/* {this.handleGroupFormView(segment, segment_index)} */}
								<div className='add-group-form'>
									<Input disabled={!isAdmin} className='group-input' value={segment.addGroup} placeholder='Add group' onChange={(e) => this.handleGroupChanges('addGroup', e.target.value, segment_index)} />
									{segment.addGroup !== undefined &&
										<>
											<div className='select-option-part'>
												<Select
													dropdownClassName='custom-select-option'
													placeholder='Administered By'
													className='custom-select-multiple'
													size='large'
													mode='multiple'
													onChange={(value) => this.handleGroupChanges('administeredBy', value, segment_index)}
												>
													{admins && admins.length > 0 && admins.map((key) => {
														return (
															<Option key={key.uid} value={key.uid}>{key.firstName + ' ' + key.lastName}</Option>
														);
													})}
												</Select>
												{segment.selectError === true &&
													<p className='no-mb error'>Please select admininstrated users for group</p>
												}
											</div>
											<Button
												type='primary'
												htmlType='submit'
												size='large'
												className='site-btn submitBtn'
												onClick={() => this.addNewGroup(segment_index)}>
												Add Group
											</Button>
										</>
									}
								</div>
							</div>
						}
					</div>
				);
			})
		);
	}

	getSegmentIndex(record) {
		const { segments }= this.state;
		let segment_index;
		segments.forEach((seg, ind) => {
			if (seg.$key === record.segment_id) {
				segment_index = ind;
			}
		});
		return segment_index;
	}

	render() {
		const { segments, addSection, isAdmin, isLoading, addSegments, showAllAccordion, addSegmentTable, showModal, searchedSegments, searchInput, isPermitted } = this.state;
		const { Column } = Table;

		if (isLoading === true) {
			return (
				<div className='loader'>
					<img src={loader} alt='loader' />
				</div>
			);
		}
		return (
			<>
				<div className='card-body'>
					<Row>
						<Col span={12}>
							<div className='groups-icon-title'>
								<Icon type='team' />
								<h4 className='card-title no-mb'>Groups</h4>
							</div>
						</Col>
						<Col span={12}>
							<Search
								placeholder='Search'
								onSearch={value => this.searchSegment(value)}
								style={{ width: '100%' }}
								className='rigSearch'
								allowClear={true}
								onChange={data => this.searchSegment(data.target.value)}
							/>
						</Col>
					</Row>
				</div>
				<div className='card-body'>
					<div className='sticky-accordion-part'>
						<Row>
							<Col span={12}>
								<button className='transparent site-btn icon' onClick={() => this.hideShowAllAccordion()}>
									{!showAllAccordion ?
										<IoIosAddCircleOutline size={18} />
										:
										<IoIosRemoveCircleOutline size={18} />
									}
									{!showAllAccordion ? 'Expand All' : 'Collapse All'}
								</button>
							</Col>
							{isPermitted &&
								<Col span={12} className='text-right'>
									<button onClick={() => this.addSegmentTableBtn()} className='site-btn'>Add segment</button>
								</Col>
							}
						</Row>
					</div>
					{addSegmentTable &&
						<div className='accordion-custom addSegment-accordion'>
							<h6 className='accordion-head'>
								{/* <div className='left-part' onClick={() => this.hideShowNewAccordion()}> */}
								<div className='left-part'>
									<div className='drag-input'>
										<img className='dragIcon' src={dragmoveImg} alt='drag move' />
										{/* Segment name */}
										<Input className='add-rename-input'
											disabled={!isAdmin}
											value={addSection}
											onChange={(e) => this.onInputChange('addSection', e.target.value)}
											onPressEnter={() => this.addNewSection()}
										/>
									</div>
									<Icon type='check-circle' className='checkIcon deletIcon' onClick={() => this.addNewSection()} />
									<Icon type='delete' className='error-text deletIcon' onClick={() => this.deleteSegmentConfirm()} />
								</div>
								<div className='group-right-chips'>
									<Icon type='team' />
									<span>0</span>
									Groups
								</div>
							</h6>
							{((showAllAccordion === true && addSegments[0].selected === true) || addSegments[0].selected === true) &&
								addSegments.map((addSegment, index) => {
									return (
										<div className='accordion-content' key={index.toString()}>
											<Table dataSource={addSegment.groups} rowKey={(record, i) => i} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
												<Column title='Group Name' dataIndex='name' key='name' />
												<Column title='Connected' dataIndex='connected' key='connected' />
												<Column title='Created By' dataIndex='createdBy' key='createdBy' />
												<Column title='Administered By' dataIndex='administeredBy' key='administeredBy' />
											</Table>
										</div>
									);
								})
							}
						</div>
					}
					{this.renderSegments(searchInput === '' ? segments : searchedSegments)}
					<Modal className='send-mail-modal' title='Delete Segment' visible={showModal} onOk={() => this.deleteSegment()} onCancel={() => this.handleCancel()}>
						<p className='text-center no-mb'><b>Are you sure, you want to delete this segment ?</b></p>
					</Modal>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(SmartGroups));